.container {
  display: flex;
  flex-direction: column;

  position: fixed;

  min-width: 1072px;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #e5eaee;

  &::before {
    display: block;
    content: '';
    position: absolute;
    left: -168px;
    top: 0;
    bottom: 0;
    width: 168px;
    background-image: url('../../assets/icons/gradient.svg');
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1 1 auto;

    padding: 40px;
    max-height: 84px;
    padding-bottom: 0;
  }

  .state {
    display: flex;
    justify-content: center;
    align-items: center;

    &-label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 100%;

      color: #000;
    }

    &-counter {
      background-color: #e58e1a;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;

      margin-left: 16px;

      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 100%;
      color: #000;
      text-align: center;
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(2, 480px);
    grid-template-rows: repeat(1fr, auto);
    gap: 30px 32px;

    margin-top: 32px;
    padding-left: 40px;
    padding-bottom: 40px;

    overflow-y: auto;

    &::-webkit-scrollbar {
      height: 8px;
      width: 4px;

      &-thumb {
        background: #c8c8c8;
      }
    }
  }
}
