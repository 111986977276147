.region {
  &-container {
    transition: all 0.3s ease-in-out;
  }

  &.hoverable {
    @media (hover: hover) and (pointer: fine) {
      &:not(.disabled) .region-container:hover {
        cursor: pointer;
        fill: #e58e1a;

        & + .region-name {
          fill: #000;
        }
      }

      &:not(.disabled) .region-name:hover {
        cursor: pointer;
        fill: #000;

        & ~ .region_container {
          fill: #e58e1a;
        }
      }
    }
  }

  &-name {
    transition: all 0.3s ease-in-out;
  }

  &.disabled {
    .region-container {
      fill-opacity: 0.5;
    }

    .region-name {
      fill-opacity: 0.4;
    }
  }

  &.not-visible .region-name {
    visibility: hidden;
  }

  &:not(.disabled).selected {
    .region-container {
      fill: #e58e1a;
      fill-opacity: 1 !important;
    }

    .region-name {
      fill: #000;
      fill-opacity: 1 !important;
    }
  }
}
