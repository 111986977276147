.nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  border: none;

  background-color: #fff;

  border-bottom: 1px solid #c8c8c8;
  width: 100%;

  &-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000;
  }

  &-active {
    border-left: 4px solid #9b9b9b;
  }

  &-counter {
    background-color: #e58e1a;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #000;
    text-align: center;
  }
}
