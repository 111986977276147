.header {
  display: flex;
  align-items: center;
  padding: 0 24px;

  height: 100px;
  width: 480px;
  position: fixed;

  background: #000;

  z-index: 1000;

  &-logo {
    background-color: transparent;
    border: none;
    padding: 0;
  }
}

.logo {
  padding: 0 24px;
}

.toggleFullscreen {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: red;
  z-index: 999;
}