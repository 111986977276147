.site-wrapper {
  color: #fff;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.6);
  background-image: url("../../../public/images/loginBackground.jpg");
  background-size: cover;
  display: table;
  width: 100%;
  height: 100vh; /* For at least Firefox */
  min-height: 100%;
  -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.5);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}

.site-wrapper-inner {
  display: table-cell;
  vertical-align: top;
}
.cover-container {
  margin-right: auto;
  margin-left: auto;
}

/* Padding for spacing */
.inner {
  padding-top: 8px;
}

.masthead h3 {
  font-size: 28px;
  font-weight: 500;
}

/*
 * Header
 */
.masthead-brand {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .masthead-brand {
    float: left;
  }
}

/*
 * Cover
 */
.cover {
  padding: 20px;
  background: #000;
  opacity: 0.8;
  padding: 20px;
}
.cover h1 {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}

/*
 * Affix and center
 */
@media (min-width: 768px) {
  /* Pull out the header and footer */
  .masthead {
    position: fixed;
    top: 0;
  }
  /* Start the vertical centering */
  .site-wrapper-inner {
    vertical-align: middle;
  }
  /* Handle the widths */
  .masthead,
  .cover-container {
    width: 100%; /* Must be percentage or pixels for horizontal alignment */
  }
}

@media (min-width: 992px) {
  .masthead,
  .mastfoot,
  .cover-container {
    width: 724px;
  }
}

.keyboard-wrapper {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  transition: 0.3s all ease-in-out;
}

.keyboard-icon {
  width: 30px;
  height: 30px;
}

.keyboard-icon path {
  stroke: #fff !important;
}

.keyboard-icon circle {
  stroke: #fff !important;
  fill: #fff !important;
}

.keyboard-icon rect {
  stroke: #fff !important;
}

.simple-keyboard {
  max-width: 850px;
}

.simple-keyboard.myTheme1 {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #1c4995;
  color: white;
}

#root .simple-keyboard.myTheme1 + .simple-keyboard-preview {
  background: #1c4995;
}