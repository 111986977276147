.toggleFullscreen {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 48px;
    height: 48px;
    background: rgba(0,0,0, 0.8) url("./assets/icons/expand.svg");
    cursor: pointer;
    z-index: 999999;
}

.hidden {
    opacity: 0;
}

.circle {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
}

.overlay {
    width: 100vw; height: 100vh;
    position: fixed; top: 0; left: 0; z-index: 1001;
    background: rgba(0, 0, 0, 0.5);
}