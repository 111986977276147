/* roboto-100 - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-100.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-100.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-100.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-100italic - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-100italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-100italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-100italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-300italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-300italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-300italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-500.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-500italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-500italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-500italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-700italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-700italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-900.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-900.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-900.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900italic - latin-ext_latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/roboto-v30-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/roboto-v30-latin-ext_latin-900italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-900italic.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/roboto-v30-latin-ext_latin-900italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../assets/fonts/roboto-v30-latin-ext_latin-900italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
