.button {
  padding: 12px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  max-height: 44px;

  display: flex;
  align-items: center;

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000;

    margin-left: 8px;
  }
}
