.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 0;
  background: transparent;

  padding: 0 20px;

  &:hover {
    cursor: pointer;
  }

  &-label {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #ffffff;
  }

  &-trigger {
    padding: 4px 0;

    margin-left: 16px;

    &-line {
      width: 34px;
      height: 2px;
      background-color: #e9ff00;
      display: block;
      margin: 6px auto;
      transition: all 0.3s ease-in-out;
      border-radius: 1px;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-active {
      .dropdown-trigger-line:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
      }

      .dropdown-trigger-line:nth-child(2) {
        opacity: 0;
      }

      .dropdown-trigger-line:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }
}
