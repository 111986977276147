.controls-sidebar {
  padding-bottom: 32px;
  padding-right: 40px;

  &-group {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    & + & {
      margin-top: 16px;
    }
  }

  &-wrapper {
    height: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1000;
    max-height: 100vh;

    width: 168px;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    border: 0;
    background-color: #fff;
    border: 1px solid #dadada;

    max-width: 58px;
    max-height: 58px;

    padding: 16px;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-bottom: none;
    }

    &:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
}
