.sidebar {
  position: fixed;

  z-index: 1000;
  background-color: #fff;

  border-right: 1px solid #c8c8c8;

  max-width: 480px;
  width: 480px;

  bottom: 0;
  top: 100px;

  overflow: auto;
  transition: all 0.3s ease-in-out;

  &::-webkit-scrollbar {
    height: 8px;
    width: 4px;

    &-thumb {
      background: #c8c8c8;
    }
  }
}
