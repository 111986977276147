.card {
  display: grid;
  grid-template-rows: 1fr auto;
  flex-direction: column;

  background-color: #fff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  max-width: 480px;

  transition: all 0.3s ease-in-out;

  height: 100%;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: 24px 32px;
    gap: 24px;

    &-info {
      flex-direction: column;
      justify-content: center;

      h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        color: #000000;

        margin-bottom: 12px;
      }

      &-address {
        display: grid;
      }

      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        min-height: 20px;

        color: #515353;
      }

      span.card-header-info-phone {
        display: flex;
        align-items: center;
        margin-top: 12px;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;

        color: #000000;

        svg {
          margin-right: 8px;
        }
      }
    }

    &-qr,
    &-qr img {
      max-width: 120px;
      max-height: 120px;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 24px 32px;
    background-color: #f2f5f7;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    height: 136px;
    max-height: 136px;

    &-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 140%;
      text-transform: uppercase;
      color: #000;
    }

    &-equipment {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 16px;
    }
  }
}
