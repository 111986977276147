@import 'fonts';

* {
  box-sizing: border-box;
}

html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;

  font-family: 'Roboto';
  background-color: #e5eaee;
}

body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
  overflow: hidden;

  font-family: 'Roboto';
}

#root {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  width: 100%;

  background-color: #e5eaee;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

a {
  background-color: transparent;
  text-decoration: none;
}
